import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core'
import {CommonModule} from '@angular/common'
import {BehaviorSubject} from "rxjs"
import {StatesService} from "../../../services/states.service"


@Component({
    selector: 'app-gallery-item',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './gallery-item.component.html',
    styleUrls: ['./gallery-item.component.scss']
})
export class GalleryItemComponent implements OnInit {

    @Input("shop-image-url") shopImageUrl!: string
    @Input("shop-typo-url") shopTypoUrl!: string

    @Input("mapPointHovered$") mapPointHovered$!: BehaviorSubject<boolean>
    @Input("hovered$") hovered$!: BehaviorSubject<boolean>

    @Output() click = new EventEmitter<void>()

    @ViewChild("image") shopImage!: ElementRef<HTMLImageElement>
    @ViewChild("darkfilter") darkFilterDiv!: ElementRef<HTMLDivElement>
    @ViewChild("typo") typoImage!: ElementRef<HTMLImageElement>


    constructor(
        private statesService: StatesService
    ) {
    }


    @HostListener("window:resize")
    onWindowResize() {
        // Höhe der Bilder an das hinterste Bild anpassen
        const height: number = this.shopImage.nativeElement.offsetHeight
        this.darkFilterDiv.nativeElement.style.height = `${height}px`
        this.typoImage.nativeElement.style.height = `${height}px`

        // Höhe für globale Verwendung weitergeben
        this.statesService.galleryHeight$.next(height)
    }


    ngOnInit(): void {
        // Ständig die Höhe der Bilder an das hinterste Bild anpassen
        setInterval(this.onWindowResize.bind(this), 500)
    }


    get highlighted() {
        return this.mapPointHovered$.value || this.hovered$.value
    }


}
