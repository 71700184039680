<!-- Galerie BEGIN -->
<div
  class="gallery-container row gx-1 gy-0"
  [class.hidden]="(visibleShopUrl$ | async)"
>

  <!-- Shop 1 BEGIN -->
  <div class="col-12 col-lg-4">
    <div #galleryItemContainer>
      <app-gallery-item
        [shop-image-url]="shop1ImageUrl"
        [shop-typo-url]="shop1TypoUrl"
        [mapPointHovered$]="mapPoint1Hovered$"
        [hovered$]="galleryShop1Hovered$"
        (click)="shopItemNumber$.next(1)"
      ></app-gallery-item>
    </div>
  </div>
  <!-- Shop 1 END -->

  <!-- Shop 2 BEGIN -->
  <div class="col-12 col-lg-4">
    <app-gallery-item
      [shop-image-url]="shop2ImageUrl"
      [shop-typo-url]="shop2TypoUrl"
      [mapPointHovered$]="mapPoint2Hovered$"
      [hovered$]="galleryShop2Hovered$"
      (click)="shopItemNumber$.next(2)"
    ></app-gallery-item>
  </div>
  <!-- Shop 2 END -->

  <!-- Shop 3 BEGIN -->
  <div class="col-12 col-lg-4">
    <app-gallery-item
      [shop-image-url]="shop3ImageUrl"
      [shop-typo-url]="shop3TypoUrl"
      [mapPointHovered$]="mapPoint3Hovered$"
      [hovered$]="galleryShop3Hovered$"
      (click)="shopItemNumber$.next(3)"
    ></app-gallery-item>

  </div>
  <!-- Shop 3 END -->

</div>
<!-- Galerie END -->

<!-- Shop-Anzeige BEGIN -->
<div class="shop-container">
  <app-shop-item
    [close-icon-url]="closeIconUrl"
  ></app-shop-item>
</div>
<!-- Shop-Anzeige END -->

