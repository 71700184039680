<div
  class="gallery-item-container"
  (mouseenter)="hovered$.next(true)"
  (mouseleave)="hovered$.next(false)"
  (mouseout)="hovered$.next(false)"
  (click)="click.emit()"
>
  <img #image class="image" alt="" [src]="shopImageUrl"/>
  <div
    #darkfilter
    class="dark-filter"
    [style.opacity]="!highlighted ? '50%' : '0'"
  ></div>
  <img #typo class="typo" alt="" [src]="shopTypoUrl"/>
</div>
