import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core'
import {CommonModule} from '@angular/common'
import {GalleryItemComponent} from "./gallery-item/gallery-item.component"
import {ShopItemComponent} from "./shop-item/shop-item.component"
import {StatesService} from "../../services/states.service"
import {Subscription} from "rxjs"


@Component({
    selector: 'ischgl-rent-gallery',
    standalone: true,
    imports: [
        CommonModule,
        GalleryItemComponent,
        ShopItemComponent,
    ],
    templateUrl: './ischgl-rent-gallery.component.html',
    styleUrls: ['./ischgl-rent-gallery.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class IschglRentGalleryComponent implements OnInit, OnDestroy {

    @Input("shop-1-image-url") shop1ImageUrl!: string
    @Input("shop-1-typo-url") shop1TypoUrl!: string
    @Input("shop-1-content-url") shop1ContentUrl!: string

    @Input("shop-2-image-url") shop2ImageUrl!: string
    @Input("shop-2-typo-url") shop2TypoUrl!: string
    @Input("shop-2-content-url") shop2ContentUrl!: string

    @Input("shop-3-image-url") shop3ImageUrl!: string
    @Input("shop-3-typo-url") shop3TypoUrl!: string
    @Input("shop-3-content-url") shop3ContentUrl!: string

    @Input("close-icon-url") closeIconUrl!: string

    mapPoint1Hovered$ = this.statesService.mapPoint1Hovered$
    mapPoint2Hovered$ = this.statesService.mapPoint2Hovered$
    mapPoint3Hovered$ = this.statesService.mapPoint3Hovered$

    galleryShop1Hovered$ = this.statesService.galleryShop1Hovered$
    galleryShop2Hovered$ = this.statesService.galleryShop2Hovered$
    galleryShop3Hovered$ = this.statesService.galleryShop3Hovered$

    visibleShopUrl$ = this.statesService.visibleShopUrl$
    shopItemNumber$ = this.statesService.shopItemNumber$
    showShopItemSubscription!: Subscription

    @ViewChild("galleryItemContainer") galleryItemContainerRef!: ElementRef<HTMLDivElement>


    constructor(
        private statesService: StatesService,
    ) {
    }


    ngOnInit(): void {

        // Darauf horchen, falls ein Shop angezeigt werden soll
        this.showShopItemSubscription = this.shopItemNumber$.subscribe((shopNumber) => {

            // Gültige URLs ermitteln
            const shop1ContentUrl = new URL(this.shop1ContentUrl, window.location.origin)
            const shop2ContentUrl = new URL(this.shop2ContentUrl, window.location.origin)
            const shop3ContentUrl = new URL(this.shop3ContentUrl, window.location.origin)

            const iframeUrl = new Map()
            iframeUrl.set(0, null)
            iframeUrl.set(1, shop1ContentUrl)
            iframeUrl.set(2, shop2ContentUrl)
            iframeUrl.set(3, shop3ContentUrl)
            this.visibleShopUrl$.next(iframeUrl.get(shopNumber))
        })

    }


    ngOnDestroy() {
        if (this.showShopItemSubscription) {
            this.showShopItemSubscription.unsubscribe()
        }
    }

}
