<div
  class="map-point-container"
  (mouseenter)="hovered$.next(true)"
  (mouseleave)="hovered$.next(false)"
  (mouseout)="hovered$.next(false)"
  (click)="click.emit()"
>
  <img
    [style.opacity]="!highlighted ? '1' : '0'"
    class="point-image"
    alt=""
    [src]="pointDefaultUrl"
  />
  <img
    [style.opacity]="highlighted ? '1' : '0'"
    class="point-image"
    alt=""
    [src]="pointHighlightedUrl"
  />
</div>
