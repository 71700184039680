<div class="shop-item-container">

  <div #iframeContainer class="iframe-container">
  </div>

  <div
    *ngIf="iframeInitialized"
    class="close-icon-container"
    (click)="onClose($event)"
  >
    <img
      class="close-icon"
      alt="close"
      [src]="closeIconUrl"
      (click)="onClose($event)"
    />
  </div>

</div>
