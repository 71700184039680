<div class="ischgl-rent-map-outer-container">

  <div
    #container
    id="ischgl-rent-map-container"
    class="map-container"
    [ngClass]="{intersecting: intersecting}"
  >
    <div class="map-container-inner">

      <img #background class="image" alt="" [src]="mapBackgroundUrl"/>
      <img #drawings class="drawings" alt="" [src]="mapDrawingsUrl"/>

      <div
        #darkfilter
        class="dark-filter"
        [style.opacity]="darken ? '60%' : '0'"
      ></div>

      <img
        #building1highligted
        class="building-highlighted"
        [src]="building1HighlightedUrl"
        [style.opacity]="highlightBuilding1 ? '100%' : '0'"
      />

      <img
        #building2highligted
        class="building-highlighted"
        [src]="building2HighlightedUrl"
        [style.opacity]="highlightBuilding2 ? '100%' : '0'"
      />

      <img
        #building3highligted
        class="building-highlighted"
        [src]="building3HighlightedUrl"
        [style.opacity]="highlightBuilding3 ? '100%' : '0'"
      />

      <div
        #exitclick
        class="exitclick"
        (click)="shopItemNumber$.next(0)"
      ></div>

      <app-map-point
        id="map-point-1"
        [point-default-url]="point1DefaultUrl"
        [point-highlighted-url]="point1HighlightedUrl"
        [galleryShopHovered$]="galleryShop1Hovered$"
        [hovered$]="mapPoint1hovered$"
        [force-highlighted]="(shopItemNumber$ | async) === 1"
        (click)="shopItemNumber$.next(1)"
      >
      </app-map-point>

      <app-map-point
        id="map-point-2"
        [point-default-url]="point2DefaultUrl"
        [point-highlighted-url]="point2HighlightedUrl"
        [galleryShopHovered$]="galleryShop2Hovered$"
        [hovered$]="mapPoint2hovered$"
        [force-highlighted]="(shopItemNumber$ | async) === 2"
        (click)="shopItemNumber$.next(2)"
      >
      </app-map-point>

      <app-map-point
        id="map-point-3"
        [point-default-url]="point3DefaultUrl"
        [point-highlighted-url]="point3HighlightedUrl"
        [galleryShopHovered$]="galleryShop3Hovered$"
        [hovered$]="mapPoint3hovered$"
        [force-highlighted]="(shopItemNumber$ | async) === 3"
        (click)="shopItemNumber$.next(3)"
      >
      </app-map-point>


    </div>

  </div>


  <!-- Linker Pfeil BEGIN -->
  <div
    #navigationContainerLeft
    class="navigation-container navigation-container-left"
    [class.visible]="intersecting && isSmallScreen"
    (click)="onNavLeft()"
  >
    <span>❮</span>
  </div>
  <!-- Linker Pfeil END -->

  <!-- Rechter Pfeil BEGIN -->
  <div
    #navigationContainerRight
    class="navigation-container navigation-container-right"
    [class.visible]="intersecting && isSmallScreen"
    (click)="onNavRight()"
  >
    <span>❯</span>
  </div>
  <!-- Rechter Pfeil END -->

</div>
