import {AfterViewInit, Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core'
import {CommonModule} from '@angular/common'
import {MapPointComponent} from "./map-point/map-point.component"
import {StatesService} from "../../services/states.service"
import {Subscription} from "rxjs"


const SCROLL_POSITION_CENTER_PERCENT = 40
const SCROLL_POSITION_POINT_1_PERCENT = 24
const SCROLL_POSITION_POINT_2_PERCENT = 52
const SCROLL_POSITION_POINT_3_PERCENT = 92


@Component({
    selector: 'ischgl-rent-map',
    standalone: true,
    imports: [CommonModule, MapPointComponent],
    templateUrl: './ischgl-rent-map.component.html',
    styleUrls: ['./ischgl-rent-map.component.scss'],
})
export class IschglRentMapComponent implements OnInit, OnDestroy, AfterViewInit {

    mapBackgroundUrl!: string
    private mapBackgroundImagePreload!: HTMLImageElement
    mapDrawingsUrl!: string
    private mapDrawingsImagePreload!: HTMLImageElement

    @Input("point-1-default-url") point1DefaultUrl!: string
    @Input("point-1-highlighted-url") point1HighlightedUrl!: string

    @Input("point-2-default-url") point2DefaultUrl!: string
    @Input("point-2-highlighted-url") point2HighlightedUrl!: string

    @Input("point-3-default-url") point3DefaultUrl!: string
    @Input("point-3-highlighted-url") point3HighlightedUrl!: string

    @Input("building-1-highlighted-url") building1HighlightedUrl!: string
    @Input("building-2-highlighted-url") building2HighlightedUrl!: string
    @Input("building-3-highlighted-url") building3HighlightedUrl!: string

    @ViewChild("container") containerDiv!: ElementRef<HTMLDivElement>
    @ViewChild("background") backgroundImage!: ElementRef<HTMLImageElement>
    @ViewChild("drawings") drawingsImage!: ElementRef<HTMLImageElement>
    @ViewChild("darkfilter") darkFilterDiv!: ElementRef<HTMLDivElement>
    @ViewChild("building1highligted") building1highligtedImage!: ElementRef<HTMLImageElement>
    @ViewChild("building2highligted") building2highligtedImage!: ElementRef<HTMLImageElement>
    @ViewChild("building3highligted") building3highligtedImage!: ElementRef<HTMLImageElement>
    @ViewChild("exitclick") exitclickDiv!: ElementRef<HTMLDivElement>

    @ViewChild("navigationContainerLeft") navigationContainerLeft!: ElementRef<HTMLDivElement>
    @ViewChild("navigationContainerRight") navigationContainerRight!: ElementRef<HTMLDivElement>

    mapPoint1hovered$ = this.statesService.mapPoint1Hovered$
    mapPoint2hovered$ = this.statesService.mapPoint2Hovered$
    mapPoint3hovered$ = this.statesService.mapPoint3Hovered$

    galleryShop1Hovered$ = this.statesService.galleryShop1Hovered$
    galleryShop2Hovered$ = this.statesService.galleryShop2Hovered$
    galleryShop3Hovered$ = this.statesService.galleryShop3Hovered$

    intersectionObserver!: IntersectionObserver
    intersecting = false
    isSmallScreen = false
    onSmallScreenScrolled = false

    shopItemNumber$ = this.statesService.shopItemNumber$
    shopItemNumberSubscription!: Subscription


    constructor(
        public statesService: StatesService,
        private ngZone: NgZone,
        private renderer: Renderer2
    ) {
    }


    @Input("map-background-url")
    set mapBackgroundUrlSetter(value: string) {
        this.mapBackgroundUrl = value
        // Bild vorladen
        this.mapBackgroundImagePreload = new Image()
        this.mapBackgroundImagePreload.src = value
    }


    @Input("map-drawings-url")
    set mapDrawingsUrlSetter(value: string) {
        this.mapDrawingsUrl = value
        // Bild vorladen
        this.mapDrawingsImagePreload = new Image()
        this.mapDrawingsImagePreload.src = value
    }


    @HostListener("window:resize")
    onWindowResize = () => {
        // Herausfinden ob es sich um einen kleinen Screen handelt
        this.isSmallScreen = window.innerWidth < 768

        // Höhe der Bilder an das hinterste Bild anpassen
        const height: number = this.backgroundImage.nativeElement.offsetHeight
        this.containerDiv.nativeElement.style.height = `${height}px`
        this.drawingsImage.nativeElement.style.height = `${height}px`
        this.darkFilterDiv.nativeElement.style.height = `${height}px`
        this.building1highligtedImage.nativeElement.style.height = `${height}px`
        this.building2highligtedImage.nativeElement.style.height = `${height}px`
        this.building3highligtedImage.nativeElement.style.height = `${height}px`
        this.exitclickDiv.nativeElement.style.height = `${height}px`

        const div = this.containerDiv.nativeElement

        // Auf kleinen Geräten die Navigationspfeile positionieren
        if (this.isSmallScreen) {
            const scrollbarWidth = div.scrollHeight - div.clientHeight
            const styleValue = `calc(${height}px - 2rem - ${scrollbarWidth}px + 4px)`
            this.renderer.setStyle(this.navigationContainerLeft.nativeElement, "top", styleValue)
            this.renderer.setStyle(this.navigationContainerRight.nativeElement, "top", styleValue)
        }

        // Auf kleinen Geräten in die Karte scrollen
        if (this.isSmallScreen) {
            const maxScrollPos = div.scrollWidth - div.clientWidth
            const scrollPos = (maxScrollPos / 100) * SCROLL_POSITION_CENTER_PERCENT
            if (!this.onSmallScreenScrolled) {
                this.onSmallScreenScrolled = true
                this.ngZone.runOutsideAngular(() => {
                    div.scrollTo(scrollPos, 0)
                })
            }
        } else {
            this.onSmallScreenScrolled = false
            this.containerDiv.nativeElement.scrollTo(0, 0)
        }

    }


    ngOnInit(): void {

        // Intersection-Observer
        this.intersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
            for (let entry of entries) {
                if (entry.isIntersecting && entry.target.classList.contains("map-container")) {
                    this.intersecting = true
                }
            }
        })

        // Map-Container vom Intersection-Observer überwachen lassen
        const mapContainerElement = document.querySelector("div.map-container") as HTMLDivElement
        this.intersectionObserver.observe(mapContainerElement)


        this.shopItemNumberSubscription = this.statesService.shopItemNumber$.subscribe((shopNumber) => {
            if (!shopNumber) {
                return
            }

            // Auf kleinen Geräten zur Shop-Position scrollen
            const div = this.containerDiv.nativeElement
            if (this.isSmallScreen) {
                const maxScrollPos = div.scrollWidth - div.clientWidth
                const scrollPositionPercent = {
                    1: SCROLL_POSITION_POINT_1_PERCENT,
                    2: SCROLL_POSITION_POINT_2_PERCENT,
                    3: SCROLL_POSITION_POINT_3_PERCENT,
                }[shopNumber] as number
                const scrollPos = (maxScrollPos / 100) * scrollPositionPercent
                this.ngZone.runOutsideAngular(() => {
                    div.scrollTo({
                        top: 0,
                        left: scrollPos,
                        behavior: "smooth"
                    })
                })
            }

            // Oberes Ende der Karte ganz nach oben scrollen
            // (darf nicht vor dem horizontalen Scrollen gemacht werden)
            this.ngZone.runOutsideAngular(() => {
                div.scrollIntoView({
                    behavior: this.isSmallScreen ? "auto" : "smooth",
                    block: "start",
                    inline: "start"
                })
            })

        })
    }


    ngAfterViewInit() {
        // 5 sec. lang laufend die Höhe der Bilder an das hinterste Bild anpassen
        let intervalCounter = 0
        const intervalTimer = setInterval(() => {
            console.debug("Initialize map height.")
            if (intervalCounter === 10) {
                clearInterval(intervalTimer)
            }
            intervalCounter += 1
            this.onWindowResize()
        }, 500)
    }


    ngOnDestroy() {
        if (this.shopItemNumberSubscription) {
            this.shopItemNumberSubscription.unsubscribe()
        }
    }


    get darken() {
        return (
            this.mapPoint1hovered$.value ||
            this.mapPoint2hovered$.value ||
            this.mapPoint3hovered$.value ||
            this.galleryShop1Hovered$.value ||
            this.galleryShop2Hovered$.value ||
            this.galleryShop3Hovered$.value ||
            !!this.shopItemNumber$.value
        )
    }


    get highlightBuilding1() {
        return (
            this.mapPoint1hovered$.value ||
            this.galleryShop1Hovered$.value ||
            this.shopItemNumber$.value === 1
        )
    }


    get highlightBuilding2() {
        return (
            this.mapPoint2hovered$.value ||
            this.galleryShop2Hovered$.value ||
            this.shopItemNumber$.value === 2
        )
    }


    get highlightBuilding3() {
        return (
            this.mapPoint3hovered$.value ||
            this.galleryShop3Hovered$.value ||
            this.shopItemNumber$.value === 3
        )
    }


    onNavLeft() {
        let nextScrollPosPercent: number

        // Aktuelle Position ermitteln
        const div = this.containerDiv.nativeElement
        const maxScrollPos = Math.round(div.scrollWidth - div.clientWidth)
        const currentScrollPosPercent = Math.round(100 / maxScrollPos * div.scrollLeft)

        // Herausfinden, wohin gescrollt werden soll
        if (currentScrollPosPercent <= SCROLL_POSITION_POINT_1_PERCENT) {
            nextScrollPosPercent = 0
        } else if (currentScrollPosPercent <= SCROLL_POSITION_POINT_2_PERCENT) {
            nextScrollPosPercent = SCROLL_POSITION_POINT_1_PERCENT
        } else if (currentScrollPosPercent <= SCROLL_POSITION_POINT_3_PERCENT) {
            nextScrollPosPercent = SCROLL_POSITION_POINT_2_PERCENT
        } else {
            nextScrollPosPercent = SCROLL_POSITION_POINT_3_PERCENT
        }

        // Scrollen
        const scrollPos = (maxScrollPos / 100) * nextScrollPosPercent
        this.ngZone.runOutsideAngular(() => {
            div.scrollTo({
                top: 0,
                left: scrollPos,
                behavior: "smooth"
            })
        })
    }


    onNavRight() {
        let nextScrollPosPercent: number

        // Aktuelle Position ermitteln
        const div = this.containerDiv.nativeElement
        const maxScrollPos = Math.round(div.scrollWidth - div.clientWidth)
        const currentScrollPosPercent = Math.round(100 / maxScrollPos * div.scrollLeft)

        // Herausfinden, wohin gescrollt werden soll
        if (currentScrollPosPercent < SCROLL_POSITION_POINT_1_PERCENT) {
            nextScrollPosPercent = SCROLL_POSITION_POINT_1_PERCENT
        } else if (currentScrollPosPercent < SCROLL_POSITION_POINT_2_PERCENT) {
            nextScrollPosPercent = SCROLL_POSITION_POINT_2_PERCENT
        } else if (currentScrollPosPercent < SCROLL_POSITION_POINT_3_PERCENT) {
            nextScrollPosPercent = SCROLL_POSITION_POINT_3_PERCENT
        } else {
            nextScrollPosPercent = 100
        }

        // Scrollen
        const scrollPos = (maxScrollPos / 100) * nextScrollPosPercent
        this.ngZone.runOutsideAngular(() => {
            div.scrollTo({
                top: 0,
                left: scrollPos,
                behavior: "smooth"
            })
        })

    }

}
