import {AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core'
import {CommonModule} from '@angular/common'
import {v4 as uuidv4} from "uuid"
import {animate, state, style, transition, trigger, AnimationEvent} from "@angular/animations"
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser"


interface CarouselItem {
    iframeUrl: string
    iframeSrc: SafeResourceUrl
    iframeKey?: string
    // iframeElement?: HTMLIFrameElement
    iframeHeight?: number
    animationState: string
}


@Component({
    selector: 'ischgl-rent-carousel',
    standalone: true,
    imports: [
        CommonModule,
    ],
    templateUrl: './ischgl-rent-carousel.component.html',
    styleUrls: ['./ischgl-rent-carousel.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    animations: [
        trigger("carouselItem", [

            state("active", style({
                opacity: "100%",
            })),

            state("default", style({
                opacity: "0",
            })),

            transition("default <=> active", animate("800ms ease-in-out"))

            // transition("default => active", [
            //     style({
            //         opacity: "0",
            //         // zIndex: "100",
            //     }),
            //     animate(2000, style({
            //         opacity: "100%",
            //     })),
            // ]),
            //
            // transition("active => default", [
            //     style({
            //         opacity: "100%",
            //         // zIndex: "100",
            //     }),
            //     animate(2000, style({
            //         opacity: "0",
            //     })),
            // ]),

        ]),
    ]
})
export class IschglRentCarouselComponent implements OnInit, AfterViewInit {

    @Input("prev-button-id") prevButtonId!: string
    @Input("next-button-id") nextButtonId!: string
    @Input("iframe-urls") iframeUrls!: string

    @ViewChild("carousel") carouselElementRef!: ElementRef<HTMLDivElement>
    @ViewChild("carouselInner") carouselInnerElementRef!: ElementRef<HTMLDivElement>
    @ViewChild("iframeContainer") iframeContainer!: ElementRef<HTMLDivElement>

    carouselItems: CarouselItem[] = []


    constructor(
        private renderer: Renderer2,
        private domSanitizer: DomSanitizer
    ) {
    }


    ngOnInit(): void {
        // Event-Listener für Nachrichten binden
        window.addEventListener("message", this.onWindowMessageHandler, true)
    }


    ngAfterViewInit() {
        // Iframe-Definitionen generieren
        this.carouselItems = this.iframeUrls.split(",").map((item: string, index: number) => {
            const iframeUrl = new URL(item.trim(), window.location.origin).toString()
            const iframeKey = uuidv4().toString()
            const iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(iframeUrl + '?iframe-key=' + iframeKey)
            const carouselItem: CarouselItem = {
                iframeUrl,
                iframeSrc,
                iframeKey,
                animationState: index === 0 ? "active" : "default"
            }
            return carouselItem
        })

        // Prev-Button
        if (this.prevButtonId) {
            const prevButton = document.getElementById(this.prevButtonId)!
            prevButton.removeEventListener("click", this.prev)
            prevButton.addEventListener("click", this.prev)
        }

        // Next-Button
        if (this.nextButtonId) {
            const nextButton = document.getElementById(this.nextButtonId)!
            nextButton.removeEventListener("click", this.next)
            nextButton.addEventListener("click", this.next)
        }

    }


    onWindowMessageHandler = (event: MessageEvent) => {
        // Iframe-Key ermitteln
        const iframeKey = event.data?.iframeKey as string
        if (!iframeKey) {
            return
        }

        // CarouselItem ermitteln
        const carouselItem = this.carouselItems.filter((item) => item.iframeKey === iframeKey)?.[0]
        if (!carouselItem) {
            return
        }

        // Iframe-Origin prüfen
        const iframeOrigin = new URL(carouselItem.iframeUrl).origin
        if (iframeOrigin !== event.origin) {
            return
        }

        // Höhe ermitteln
        const height = event.data?.height as number
        if (height === carouselItem.iframeHeight) {
            return
        }
        carouselItem.iframeHeight = height

        // Höhe des Iframes anpassen
        console.debug(`IschglRentCarouselComponent.onWindowMessageHandler(${height})`)
        if (!!height) {
            const iframeElement = this.carouselInnerElementRef.nativeElement.querySelector(`iframe[data-key="${iframeKey}"]`)
            if (!!iframeElement) {
                this.renderer.setStyle(iframeElement, "height", `${height}px`)
            }
        }

        // Höhe des Containers (carousel-inner) an das aktuelle CarouselItem anpassen
        const activeCarouselItem = this.carouselInnerElementRef.nativeElement.querySelector(".carousel-item.active")
        if (!!activeCarouselItem) {
            const activeCarouselItemHeight = activeCarouselItem.getBoundingClientRect().height
            this.renderer.setStyle(
                this.carouselInnerElementRef.nativeElement,
                "height",
                `${activeCarouselItemHeight}px`
            )
        }

    }


    next = () => {
        for (let index = 0; index < this.carouselItems.length; index++) {
            if (this.carouselItems[index].animationState === "active") {
                this.carouselItems[index].animationState = "default"
                if (index < this.carouselItems.length - 1) {
                    this.carouselItems[index + 1].animationState = "active"
                } else {
                    this.carouselItems[0].animationState = "active"
                }
                break
            }
        }
    }


    prev = () => {
        for (let index = 0; index < this.carouselItems.length; index++) {
            if (this.carouselItems[index].animationState === "active") {
                this.carouselItems[index].animationState = "default"
                if (index === 0) {
                    this.carouselItems[this.carouselItems.length - 1].animationState = "active"
                } else {
                    this.carouselItems[index - 1].animationState = "active"
                }
                break
            }
        }
    }


    onAnimationStart(event: AnimationEvent) {
        if (
            event.fromState === "default" &&
            event.toState === "active"
        ) {
            const activeCarouselItemHeight = event.element.getBoundingClientRect().height
            this.renderer.setStyle(
                this.carouselInnerElementRef.nativeElement,
                "height",
                `${activeCarouselItemHeight}px`
            )
        }
    }


}

